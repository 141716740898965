var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"card-shadow border-radius-l"},[_c('div',{staticClass:"card-header-padding"},[_c('h5',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v("Sprachen")]),_c('p',{staticClass:"text-sm text-body mb-0"},[_vm._v("Suche in Sprachen nach: "),_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.$route.query.searchTerm))])])]),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.languages,"search":_vm.search,"page":_vm.page,"hide-default-footer":"","items-per-page":_vm.itemsPerPage,"mobile-breakpoint":"0"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-sm font-weight-normal text-body"},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-sm font-weight-normal text-body"},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.age",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-sm font-weight-normal text-body"},[_vm._v(" "+_vm._s(item.age)+" ")])]}},{key:"item.salary",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-sm font-weight-normal text-body"},[_vm._v(" "+_vm._s(item.salary)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"btn-ls me-2 my-2 rounded-sm",attrs:{"icon":"","elevation":"0","ripple":false,"height":"28","min-width":"36","width":"36","color":"#67748e"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"size":"12"}},[_vm._v("ni-ruler-pencil")])],1),_c('v-btn',{staticClass:"btn-ls me-2 my-2 rounded-sm",attrs:{"icon":"","elevation":"0","ripple":false,"height":"28","min-width":"36","width":"36","color":"#67748e"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"size":"12"}},[_vm._v("ni-fat-remove text-lg")])],1)]}}])})],1),_c('v-card-actions',{staticClass:"card-padding"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"6","lg":"3"}},[_c('span',{staticClass:"text-body me-3 text-sm"},[_vm._v("Einträge pro Seite:")]),_c('v-text-field',{staticClass:"\n              font-size-input\n              placeholder-lighter\n              text-color-light\n              input-alternative input-focused-alternative input-icon\n            ",attrs:{"hide-details":"","type":"number","outlined":"","min":"-1","max":"15","background-color":"rgba(255,255,255,.9)","color":"rgba(0,0,0,.6)","light":"","value":_vm.itemsPerPage,"placeholder":"Items per page"},on:{"input":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1),_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#cb0c9f","length":_vm.pageCount,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }